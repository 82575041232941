import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type CSSProperties, type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokServicesListType } from '../../model/storyblokTypes.generated'
import { getNonEmptyStyleStringValue } from '../../styles/getNonEmptyStyleStringValue'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

import styles from './StoryblokServicesList.module.css'

export const StoryblokServicesList: FC<
  StoryblokComponentProps<StoryblokServicesListType>
> = ({ blok }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className="w-full rounded-2xl px-4 py-3 shadow-md desktop:pb-2 desktop:pt-4"
    >
      <ul
        className={clsx(
          'w-full gap-x-6 gap-y-3 overflow-hidden pb-1',
          blok.tabletColumns !== '1' && 'tablet:pb-0',
          blok.desktopColumns !== '1' && 'desktop:pb-0',
          styles['storyblok-services-list'],
        )}
        style={
          {
            '--grid-columns-desktop': getNonEmptyStyleStringValue(
              blok.desktopColumns,
            ),
            '--grid-columns-tablet': getNonEmptyStyleStringValue(
              blok.tabletColumns,
            ),
          } as CSSProperties
        }
      >
        <StoryblokComponentsRenderer bloks={blok.items} />
      </ul>
    </div>
  )
}
